<!--
 * @Page:预约记录
 * @Author: 张阳帅
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-11-20 09:58:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\appointList.vue
-->
<template>
  <div class="appointt_list_page">
    <van-sticky :offset-top="50">
      <div class="page_top">
        <div class="one_item">
          <div class="item_left">
            就诊人
          </div>
          <div class="item_name">
            {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
          </div>
          <div class="item_right">
            {{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}
          </div>
        </div>
      </div>
    </van-sticky>
    <page-refresh-load :data-source="dataSource" :total="total" :refreshing="refreshing" :loading="loading"
                       @refreshing="onRefresh" @load-more="onLoad"
    >
      <gjc-appointment-item :data="dataSource" type="0" @refresh="onRefresh" />
    </page-refresh-load>
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'

export default {
  name: 'AppointmentList',
  mixins: [pageRefreshLoad],
  data() {
    return {
      patientDict: {},//就诊人字典
      patientCardId: '',
      patientInfoId: '',
      pageSize: 4,
      dataSource: []
    }
  },
  created() {
    this.patientDict = JSON.parse(this.$store.getters.patient)
    this.patientId = this.patientDict.patientId
    this.patientCardId = this.patientDict.patientCardList[0].patientCardId
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    async onRefresh() {
        this.page = 0
        this.dataSource = []
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.refreshing = true
        await this.onLoad()
      },
      
    //查询预约记录
    async getList() {
      let params = {
        patientCardId: this.patientCardId,
        patientInfoId: this.patientId,
        page: this.page,
        pageSize: this.pageSize
      }
      const res = await gjcModel.getQueryAppointRecordPageData(params)
      console.log('查询预约记录', res)
      this.dataSource.push(...(res.data || []))
      this.total = res.count || 0
    }
  }
}
</script>
<style scoped lang='less'>
.appointt_list_page {
  background-color: #f5f5f5;
}

.page_top {
  background-color: #1e80ff;
  border-bottom: 1px solid #f5f5f5;

  .one_item {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 24px 32px;
    color: white;
    border-bottom: 1px solid #f5f5f5;
    font-size: 30px;
  }
}

.page-box {
  padding: 0 30px;
}
</style>
